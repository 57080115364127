@middle: 10px;
@small: 8px;
@large: 16px;
.account-overview {
  text-align: center;
  &-progress {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    &-limit {
      flex-shrink: 0;
    }
    .ant-progress {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      &-text {
        width: auto;
      }
    }
    .ant-progress-status-success {
      .ant-progress-text {
        color: red;
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .lending-card-container {
    .ant-card-head-wrapper {
      flex-direction: column;
      .ant-card-head-title {
        padding: 10px 0;
        margin-right: auto;
      }
      .ant-card-extra {
        padding: 0 0 10px;
        width: 100%;
      }
    }
    .ant-dropdown-menu-submenu-title {
      a.active {
        color: rgb(209, 108, 0) !important;
      }
    }
  }
}

.ant-statistic-content-value-decimal {
  font-size: 16px;
}
.warning-token-message {
  font-size: 18px;
  margin-left: 10px;
  color: red;
}
@primary-color: #EB591A;@layout-footer-background: #EB591A;@layout-header-background: #ffffff;