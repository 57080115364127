@text-color-orange: #E95A1B;

.Compensation {
  height: 100%;

  .left-container {
    height: 640px;
    padding-top: 120px;
    .big-logo {
      width: 90px;
      height: 64px;
    }
    .big-title {
      color: #5B3926;
      font-size: 40px;
    }
    .description {
      color: #999999;
      font-size: 20px;
    }
  }

  @media screen and (max-width: 576px) {
    .left-container {
      height: auto;
      padding: 0;
      .big-logo {
        margin-top: 8px;
        width: 45px;
        height: 32px;
      }
      .big-title {
        color: #5B3926;
        font-size: 20px;
      }
      .description {
        color: #999999;
        font-size: 14px;
      }
    }
  }


  .button-full-width {
    width: 100%;
    height: 48px;
    font-size: 18px;
    line-height: 1.5;
    border-radius: 8px;
    box-shadow: #EA591A 0 3px 8px 0;
    &:disabled {
      box-shadow: none;
    }
  }

  .button-full-width-login {
    box-shadow: #EEE 0 1px 2px 0;
    color: #EA591A;
    img {
      margin-right: 14px;
      height: 28px;
    }
  }

  .right-container {
    width: 480px;
    height: 640px;
    border-radius: 8px;
    background: #FFF;
    //border: 1px solid #979797;
    box-shadow: #E3E3E3 0 2px 20px 0;
    padding: 32px 0 32px 0;

    .right-card {
      width: 416px;
      height: 100%;
      display: flex;
      flex-direction: column;

      .display-none {
        display: none;
      }

      .header-buttons {
        height: 48px;
        .header-btn {
          line-height: 48px;
          height: 100%;
          color: @text-color-orange;
          text-align: center;
          background: #FAFBFC;
          font-size: 16px;
          cursor: pointer;
        }
        .left-btn {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        .right-btn {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
        .btn-active {
          color: #FFF;
          background: #E95A1B;
        }
      }

      .right-content-changeable {
        flex: 1;

        .info-logo-container {
          height: 152px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .info-logo-container-h20 {
          height: 20px;
        }

        .balance-container {
          height: 80px;
          background: #FAFBFC;
          border-radius: 8px;
          padding: 8px 12px;
          font-size: 12px;
          margin: 24px 0 24px 0;

          .option-value {
            font-size: 14px;
            font-weight: 500;
            padding: 0 0 0 8px;
          }
          .balance-context {
            font-weight: 500;
            font-size: 14px;
            line-height: 32px;
            color: #05B54F;
          }

          .ant-select-selector {
            padding: 0 8px 0 0;
          }
          .ant-select-arrow {
            font-weight: 500;
            color: #000000;
          }
        }

        .balance-container-right {
          padding: 0 16px 0 16px;
          margin-bottom: 8px;

          .balance-context {
            font-size: 20px;
          }
          .option-container {
            display: flex;
          }
          .option-value {
            padding: 6px 0 0 8px;
          }
        }
        .staked {
          margin-top: 8px;
          color: #999;
        }

        .redeem-margin-fix {
          margin-top: 136px;
        }

        .stake-input {
          width: 100%;
          border-radius: 8px;
          margin-bottom: 24px;
          height: 48px;
          font-size: 14px;
          line-height: 48px;
        }

        .stake-note-bottom {
          margin-top: 16px;
          padding: 12px 16px 12px 16px;
          background: #FEF3F3;
          border-radius: 8px;
          color: #E91B1B;
          font-size: 12px;
        }

        .plan-detail {
          span {
            cursor: pointer;
          }
          color: @text-color-orange;
          margin-bottom: 12px;
        }

        .stake-submit {
          width: 100%;
          border-radius: 8px;
          height: 48px;
        }

        .sushi-big {
          width: 64px;
          height: 64px;
          border: 64px;
          font-size: 38px;
          line-height: 64px;
          border-radius: 64px;
          box-shadow: #D5D5D5 0 4px 6px 0;
        }
        .sashimi-big {
          width: 46px;
        }
        .sashimi-middle-bg {
          width: 32px;
          height: 32px;
          border-radius: 32px;
          background: #FFF;
          box-shadow: #D5D5D5 0 4px 6px 0;
          line-height: 32px;
          text-align: center;
        }
        .sashimi-middle {
          width: 24px;
        }
        .info-blank-24 {
          height: 24px;
          width: 1px;
        }
        .info-blank-44 {
          height: 44px;
          width: 1px;
        }
      }
    }
  }

  @media screen and (max-width: 576px) {
    .right-container {
      width: auto;
      margin: 8px;
      padding: 16px;
    }
  }
}

.compensation-option-value {
  font-size: 14px;
  font-weight: 500;
  padding: 0 0 0 8px;
}

.countdown {
  font-size: 14px;
  .tag {
    padding: 0 12px 0 8px;
    height: 28px;
    line-height: 28px;
    border-radius: 8px;
    font-size: 12px;
    border: none;
  }
  .tag-pink {
    color: #E91B1B;
    background: #FEF3F3;
  }
  .tag-green {
    color: #05B54F;
    background:#EDFCF2;
  }
  .tag-grey {
    color: #4D4B46;
    background: #E8EAED;
  }
  .tag-blue {
    color: #1579FF;
    background: #E7F1FF;
  }
  .number-blue {
    span {
      color: #1579FF;
    }
  }
  .number-green {
    span {
      color: #05B54F;
    }
  }
  .number-pink {
    span {
      color: #E91B1B;
    }
  }
}

@primary-color: #EB591A;@layout-footer-background: #EB591A;@layout-header-background: #ffffff;